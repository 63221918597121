export default ({ spaces, fontSizes, breakpoints, colors }) => ({
  key: { name: "Footer" },

  footer: {
    padding: [spaces[4], 0],
    backgroundColor: colors.white,
  },

  loadImages: {
    [breakpoints.mw.md]: {
      marginLeft: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  column: {
    "&:nth-child(1)": {
      order: 1,
    },

    "&:nth-child(2)": {
      order: 3,

      marginTop: spaces[3],

      [breakpoints.lg]: {
        marginTop: 0,
        order: 2,
      },
    },

    "&:nth-child(3)": {
      order: 2,
      marginTop: spaces[3],

      [breakpoints.md]: {
        marginTop: 0,
      },

      [breakpoints.lg]: {
        order: 4,
      },
    },

    "&:nth-child(4)": {
      order: 4,

      [breakpoints.lg]: {
        order: 2,
      },
    },

    "&:nth-child(5)": {
      order: 5,
      marginTop: spaces[3],

      [breakpoints.lg]: {
        order: 2,
        marginTop: 0,
      },
    },
  },
  columnImages: {
    marginLeft: "-55px!important",
    "&:nth-child(1)": {
      order: 1,
    },
    [breakpoints.mw.md]: {
      marginLeft: '0!important',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "&:nth-child(2)": {
      order: 3,

      marginTop: spaces[3],

      [breakpoints.lg]: {
        marginTop: 0,
        order: 2,
      },
    },

    "&:nth-child(3)": {
      order: 2,
      marginTop: spaces[3],

      [breakpoints.md]: {
        marginTop: 0,
      },

      [breakpoints.lg]: {
        order: 4,
      },
    },

    "&:nth-child(4)": {
      order: 4,

      [breakpoints.lg]: {
        order: 2,
      },
    },

    "&:nth-child(5)": {
      order: 5,
      marginTop: spaces[3],

      [breakpoints.lg]: {
        order: 2,
        marginTop: 0,
      },
    },
  },
  logo: {
    width: 200,
    verticalAlign: "top",

    [breakpoints.md]: {
      marginRight: spaces[1],
    },

    [breakpoints.lg]: {
      marginRight: 0,
    },
  },

  pgbiz: {
    width: 90,
    [breakpoints.mw.md]: {
      height: "90px",
    },
  },

  facebook: {
    width: 180,
  },
  
  privacyChoiceContainer: {
      // display: "flex",
  },
  privacyChoiceImg: {
    height:"21px",
    width:"43px",
    verticalAlign: "middle",
  },
  privacyChoiceIconStyle: {
    float: "left",
    height:"21px",
    width:"43px",
    margin:"5px",
  },
  privacyChoiceLink: {
    paddingRight: "10px",
    fontSize: fontSizes.md,
    color: "blue",
    textDecoration: "underlined",
    verticalAlign: "middle",

  },

  copyright: {
    margin: [spaces[3], 0, 0],
    fontSize: fontSizes.sm,

    [breakpoints.lg]: {
      margin: 0,
      fontSize: fontSizes.md,
    },
  },
  linksContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: spaces[1]
  },
  linksPPTOS: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  links: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",

    [breakpoints.lg]: {
      flexDirection: "row",
      textAlign: "center",
    },
  },



  link: {
    // margin: [spaces[0], 0, 0],
    fontSize: fontSizes.sm,
    fontWeight: 700,
    color: colors.black,

    [breakpoints.lg]: {
      margin: 0,

      "&:first-child": {
        margin: 0,
      },
    },
  },

  socials: {
    whiteSpace: "nowrap",
    padding: "0 16px!important",
    [breakpoints.mw.xs60]: {
      padding: 0,
    },
  },

  icon: {
    fontSize: fontSizes.xl2,
    marginLeft: spaces[3],

    "&:first-child": {
      marginLeft: 0,

      [breakpoints.md]: {
        marginLeft: spaces[1],
      },

      [breakpoints.lg]: {
        marginLeft: 0,
      },
    },
  },
  footerItems: {
    marginLeft: "-100px!important",
    [breakpoints.mw.md]: {
      marginLeft: "-32px!important",
    },
  },
  awards: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "0!important",
    marginLeft: "-40px!important",
    marginRight: "40px!important",
    "&:nth-child(1)": {
      order: 1,
    },

    "&:nth-child(2)": {
      order: 3,

      marginTop: spaces[3],

      [breakpoints.lg]: {
        marginTop: 0,
        order: 2,
      },
    },

    "&:nth-child(3)": {
      order: 2,
      marginTop: spaces[3],

      [breakpoints.md]: {
        marginTop: 0,
      },

      [breakpoints.lg]: {
        order: 4,
      },
    },

    "&:nth-child(4)": {
      order: 4,

      [breakpoints.lg]: {
        order: 2,
      },
    },

    "&:nth-child(5)": {
      order: 5,
      marginTop: spaces[3],

      [breakpoints.lg]: {
        order: 2,
        marginTop: 0,
      },
    },
    [breakpoints.mw.md]: {
      marginRight: "0!important",
      marginLeft: "0!important",
      padding: "0 32px!important",
    },
  },
})
